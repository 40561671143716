import { InjectionToken } from "@angular/core";
import { LogLevel } from "@webapp/core/logging/models/logging.models";

export type EnvironmentType = "cloud" | "onprem";

export interface IEnvironmentConfig {
  name: string;
  dc: string;
  type: EnvironmentType;
  bypassDomainCheck?: boolean;
  useQuantiveBasePath?: boolean;
}

export interface IAuth0AuthConfig {
  type: "auth0";
  domainsAllowedForSignup: string[];
  mainUsersDatabaseName: string;
  dc: { [name: string]: IAuth0DcConfig };
}

export interface IAuth0DcConfig {
  domain: string;
  clientId: string;
  audience: string;
}

export interface IAzureAuthConfig {
  type: "azure";
  domainsAllowedForSignup: string[];
  clientId: string;
  authority: string;
}

export type IAuthConfig = IAuth0AuthConfig | IAzureAuthConfig;

export interface IChargebeeConfig {
  enabled: boolean;
  site?: string;
  sites?: IChargebeeItem[];
}

export interface IChargebeeItem {
  dc: string;
  site: string;
}

export interface IDelightedConfig {
  enabled: boolean;
  tags?: string[];
}

export interface IEndpointsConfig {
  secure: boolean;
  apiEndpoint: string;
  legacyApiEndpoint: string;
  webSocketsApiEndpoint: string;
  quantivePlusApi: string;
  strategyApi: string;
  webappEndpoint: string;
  websiteEndpoint: string;
  apiBaseUrlWithProtocol: string;
  wsBaseUrlWithProtocol: string;
}

export interface ILoggingConfig {
  enabled: boolean;
  tags: ILoggingConfigTags;
  endpoint: string;
  levels: LogLevel[];
}

export interface ILoggingConfigTags {
  dc: string;
  env: string;
}

export interface ISegmentConfig {
  enabled: boolean;
  writekey?: string;
}

export interface IAmplitudeConfig {
  enabled: boolean;
  amplitudeApiKey?: string;
  amplitudeServerZone?: "EU" | "US" | "local";
}

export interface ICdnStorageConfig {
  enabled: boolean;
  url: string;
}

export type ILaunchDarklyConfig = IEnabledLaunchDarklyConfig | IDisabledLaunchDarklyConfig;

export interface IEnabledLaunchDarklyConfig {
  enabled: true;
  clientKey: string;
  proxyBaseUrl: string;
  eventBaseUrl: string;
}

export interface IDisabledLaunchDarklyConfig {
  enabled: false;
  enabledFeatures: string;
}

export interface IRumConfig {
  enabled: boolean;
  url: string;
  environment: string;
}

export interface IWhiteboardsConfig {
  enabled: boolean;
  socketsTimeout: number;
}

export interface IAppConfig {
  env: IEnvironmentConfig;
  auth: IAuthConfig;
  chargebee: IChargebeeConfig;
  delighted: IDelightedConfig;
  endpoints: IEndpointsConfig;
  logging: ILoggingConfig;
  segment: ISegmentConfig;
  amplitude: IAmplitudeConfig;
  cdnStorage: ICdnStorageConfig;
  clickMagick: boolean;
  launchDarkly: ILaunchDarklyConfig;
  hubSpot: IHubSpotConfig;
  rum: IRumConfig;
  whiteboards: IWhiteboardsConfig;
}

export const APP_CONFIG = new InjectionToken<IAppConfig>("AppConfig", {
  providedIn: "root",
  factory: (): IAppConfig => webappConfig(),
});

export type IHubSpotConfig = IEnabledHubSpotConfig | IDisabledHubSpotConfig;

interface IEnabledHubSpotConfig {
  enabled: boolean;
  formUrl: {
    marketingInfo: string;
  };
}

interface IDisabledHubSpotConfig {
  enabled: false;
}
