import { Auth0Client, Auth0ClientOptions, LogoutOptions } from "@auth0/auth0-spa-js";
import { v4 as uuidv4 } from "uuid";
import { IAuth0DcConfig, getPathPrefix, isLocalhost, isQuantiveDomain, isSecure, toQuantiveDomain } from "@gtmhub/env";
import { AuthCookie, IAuthCookieValue, setCookie } from "../cookie";
import { IAuthResponse } from "../models";

interface IAuth0Connection {
  connectionName: string;
  domain: string;
}

const loginsCountPropName = "https://gtmhub.com/loginsCount";
const cacheLocation = "localstorage";
const defaultDomain = "gtmhub.com";
const defaultQuantiveDomain = "quantive.com";

let auth0Client: Auth0Client;

export const getClient = (): Auth0Client => {
  if (!auth0Client) {
    const options = getClientOptions();
    if (options) {
      setClient(options);
    } else {
      throw new Error(`There was an unexpected error getting auth0 info`);
    }
  }

  return auth0Client;
};
const setClient = (args: Auth0ClientOptions): void => {
  auth0Client = new Auth0Client(args);
};

export const getAuthCookie = (args: { domain: string; secure: boolean }): IAuthCookieValue => {
  const authCookie = new AuthCookie(args.domain, args.secure);
  return authCookie.getAndRemove();
};
const setAuthCookie = (args: { domain: string; secure: boolean; state: string; nonce: string; appState?: Record<string, string> }): void => {
  const maxAgeInSecs = 10 * 60; // 10mins
  const authCookie = new AuthCookie(args.domain, args.secure);
  authCookie.set({ state: args.state, nonce: args.nonce, appState: args.appState }, { maxAgeInSecs });
};

const getCookieDomain = (hostname: string): string => {
  if (isLocalhost(hostname)) {
    return hostname;
  } else if (hostname.endsWith(defaultQuantiveDomain)) {
    return defaultQuantiveDomain;
  } else {
    return defaultDomain;
  }
};

const hasClientOptions = (): boolean => "auth0" in localStorage;
const getClientOptions = (): Auth0ClientOptions => (hasClientOptions() ? JSON.parse(localStorage.getItem("auth0")) : null);
const setClientOptions = (clientOptions: Auth0ClientOptions): void => localStorage.setItem("auth0", JSON.stringify(clientOptions));

const getUserId = (): string => (localStorage.getItem("auth0UserId") ? JSON.parse(localStorage.getItem("auth0UserId")) : null);
export const setUserId = (userId: string): void => {
  localStorage.setItem("auth0UserId", JSON.stringify(userId));
  setCookie("auth0UserId", encodeURIComponent(userId), window.location.hostname, { secure: isSecure(window.location.protocol) });
};

export const navigateToAuth0Login = (
  auth: IAuthResponse,
  email: string,
  opts: { appState?: Record<string, string>; isMsTeamsState?: boolean; bypassDomainCheck?: boolean; connection?: IAuth0Connection } = {}
): Promise<void> => {
  const state = uuidv4();
  const nonce = uuidv4();

  // remove optional /singularity suffix so that callback url is still results
  stripOptionalStrategySuffix(auth);

  // Gtmhub domain includes the protocol and the host
  // e.g., https://hello.gtmhub.com
  const [gtmhubProtocol, gtmhubHost] = auth.gtmhubDomain.split("//");
  let cookieDomain = gtmhubHost;
  let secure = isSecure(gtmhubProtocol);
  let redirectUrl = auth.redirectUrl;

  const { protocol, host, hostname } = window.location;
  if (opts.bypassDomainCheck) {
    // on localhost we allow only returns to localhost
    // this helps also when we work against staging API
    cookieDomain = hostname;
    secure = isSecure(protocol);

    if (!opts.appState) {
      opts.appState = {};
    }
    opts.appState.domain = new URL(redirectUrl).host;

    redirectUrl = `${protocol}//${pathJoin(host, getPathPrefix(), "/login/callback")}`;

    const warning = opts.bypassDomainCheck
      ? "Auth0 redirectUri will be rewritten because of bypassing domain check"
      : "Auth0 redirectUri will be rewritten because you are working on localhost";
    console.warn(warning, { old: auth.redirectUrl, new: redirectUrl });
  }

  if (isQuantiveDomain(hostname)) {
    redirectUrl = toQuantiveDomain(redirectUrl);
    cookieDomain = toQuantiveDomain(cookieDomain);
  }

  setAuthCookie({ domain: cookieDomain, secure, state, nonce, appState: opts.appState });

  const clientOptions: Auth0ClientOptions = {
    domain: auth.authDomain,
    clientId: auth.clientId,
    authorizationParams: {
      authMethod: auth.authMethod,
      audience: auth.audience,
      redirect_uri: redirectUrl,
      ...(opts.connection && opts.connection.connectionName && { connection: opts.connection.connectionName }),
      ...(email && email !== "null" && { login_hint: email }),
    },
    useRefreshTokens: true,
    cacheLocation,
    cookieDomain: getCookieDomain(cookieDomain),
    useCookiesForTransactions: true,
  };
  setClient(clientOptions);
  setClientOptions(clientOptions);

  return getClient().loginWithRedirect();
};

const buildSsoLogoutAndLoginUrl = (auth: IAuthResponse, currentGtmhubDomain: string): string => {
  const baseUrl = `https://${auth.authDomain}/v2/logout`;
  const clientId = auth.clientId;
  const returnTo = `${currentGtmhubDomain}/login`;

  return `${baseUrl}?federated&clientId=${clientId}&returnTo=${returnTo}`;
};

// ts-unused-exports:disable-next-line
export const navigateToAuth0SignUp = (dcConfig: IAuth0DcConfig): Promise<void> => {
  const state = uuidv4();
  const nonce = uuidv4();

  const { protocol, host, hostname, search } = window.location;
  const secure = isSecure(protocol);
  setAuthCookie({ domain: hostname, secure, state, nonce });

  const allowLogin = search.indexOf("invitationKey") < 0;
  const allowSignUp = true; // we want to enable singup when calling /sign-up (this is mainly used in e2e test for creating accounts)
  const invitationKey = allowLogin ? "" : "?inviteKey=" + search.split("invitationKey=")[1];

  const clientOptions: Auth0ClientOptions = {
    domain: dcConfig.domain,
    clientId: dcConfig.clientId,
    authorizationParams: {
      audience: dcConfig.audience,
      redirect_uri: `${protocol}//${pathJoin(host, getPathPrefix(), "/login/callback")}${invitationKey}`,
      initial_screen: "signUp",
      allow_login: allowLogin,
      allow_sign_up: allowSignUp,
      responseMode: "form_post",
    },
    useRefreshTokens: true,
    cacheLocation,
    cookieDomain: getCookieDomain(hostname),
    useCookiesForTransactions: true,
  };
  setClientOptions(clientOptions);

  return getClient().loginWithRedirect();
};

export const resolveAuthToken = (queryParams: { domain: string; clientId: string; audience: string }): Promise<void> => {
  const { protocol, host, hostname, href } = window.location;
  const redirectUrl = href.replace(/%20/g, "%2b");

  const clientOptions: Auth0ClientOptions = {
    domain: queryParams.domain,
    clientId: queryParams.clientId,
    authorizationParams: {
      audience: queryParams.audience,
      redirect_uri: `${protocol}//${pathJoin(host, getPathPrefix(), "/login/callback")}`,
    },
    useRefreshTokens: true,
    cacheLocation,
    cookieDomain: getCookieDomain(hostname),
    useCookiesForTransactions: true,
  };
  setClientOptions(clientOptions);

  const client = getClient();

  return client
    .handleRedirectCallback(redirectUrl)
    .then(() => Promise.all([client.getIdTokenClaims(), client.getTokenSilently()]))
    .then((result) => {
      const [idToken, accessToken] = result;
      const auth0UserId = idToken.sub;

      setUserId(auth0UserId);
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("token", JSON.stringify(idToken.__raw));
      // setting loginsCount to localStorage, as it is needed to decide whether to show delighted survey or not:
      if (idToken[loginsCountPropName]) {
        localStorage.setItem("loginsCount", idToken[loginsCountPropName].toString());
      }
      localStorage.removeItem("accountId");
      localStorage.removeItem("userId");
    })
    .catch((error) => {
      console.error(error);
      client.logout({ logoutParams: { returnTo: `${protocol}//${host}/login` } });
    });
};

const refreshTokenSilently = async (): Promise<string> => {
  try {
    const client = getClient();
    const token = await client.getTokenSilently();
    localStorage.setItem("accessToken", token);

    const idToken = await client.getIdTokenClaims();
    if (idToken) {
      localStorage.setItem("token", JSON.stringify(idToken.__raw));
    }

    return token;
  } catch (err) {
    console.error("Could not refresh the token silently", err);
    throw err;
  }
};

const isUserAuthenticated = (): Promise<boolean> => getClient().isAuthenticated();

const logout = (args?: LogoutOptions): Promise<void> => getClient().logout(args);

const clearData = (): void => {
  localStorage.removeItem("auth0UserId");
  localStorage.removeItem("auth0");
};

const pathJoin = (...paths: string[]): string => {
  let result = "";
  for (const path of paths) {
    result = result + path + "/";
  }
  result = result.replace(/\/+/g, "/").slice(0, -1);

  return result;
};

const authProvider = {
  refreshTokenSilently,
  hasClientOptions,
  getUserId,
  setUserId,
  clearData,
  logout: (options: { federated: boolean; returnTo: string }): Promise<void> => logout({ logoutParams: options }),
  isUserAuthenticated,
  buildSsoLogoutAndLoginUrl,
  navigateToAuth0Login,
};

const stripOptionalStrategySuffix = (target: IAuthResponse): void => {
  if (target.gtmhubDomain.endsWith("/singularity")) {
    target.gtmhubDomain = target.gtmhubDomain.replace("/singularity", "");
    target.redirectUrl = target.redirectUrl.replace("/singularity", "");
    target.authDomain = target.authDomain.replace("/singularity", "");
  }
  if (target.gtmhubDomain.endsWith("/strategyai")) {
    target.gtmhubDomain = target.gtmhubDomain.replace("/strategyai", "");
    target.redirectUrl = target.redirectUrl.replace("/strategyai", "");
    target.authDomain = target.authDomain.replace("/strategyai", "");
  }
};

export default authProvider;
