import "imports-loader?wrapper=true!../../../wwwroot/lib/zepto/zepto.js";
import jwtDecode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import { navigateToAuth0Login, navigateToAuth0SignUp } from "./auth/providers/auth0.provider";
import { initAzureAuth } from "./auth/providers/azure.provider";
import { getOrInitSessionID } from "./core/tracing/tracing.service";

function createHttpRequestHeaders(screenName: string): Record<string, string> {
  const sessionID = getOrInitSessionID();
  const screenID = uuidv4();
  return {
    "jaeger-baggage": `sessionID=${sessionID}`,
    "jaeger-baggage-screen": `screenID=${screenID}, screenName=${screenName}`,
    "gtmhub-application-name": "webapp",
    "gtmhub-path": screenName,
    "transaction-id": uuidv4(),
  };
}

const module = {
  createHttpRequestHeaders,
  navigateToAuth0Login,
  navigateToAuth0SignUp,
  initAzureAuth,
  uuidv4,
  jwtDecode,
};

declare global {
  interface Window {
    gtmhub: typeof module;
  }
}

window.gtmhub = module;

export { createHttpRequestHeaders, navigateToAuth0Login };
